body {  min-width:500px; overflow: hidden; }

.language-ascii-art {
  display: inline-block;
  font-family: "Lucida Console", Monaco, monospace;
  letter-spacing: -0.2em;
  line-height: 0.8em;
  text-shadow: 0 0 10px rgba(100, 100, 100, 0.5);
  white-space: pre;
  font-size: medium;
}

.show {
  opacity: 1;
  transition: opacity 2000ms;
}

.hide {
  opacity: 0;
  transition: opacity 2000ms;
}

.scale-font {
  font-size: medium;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .scale-font {
    font-size: medium;
  }
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 700px) {
  .scale-font {
    font-size: large;
  }
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 1090px) {
  .scale-font {
    font-size: xx-large;
  }
}
